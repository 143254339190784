import React from 'react';
import App from 'modules/App';
import Layout from 'modules/Layout';


function action({ route }) {
  return {
    chunks   : ['not-found'],
    name     : route.name,
    component: (
      <Layout.components.ErrorLayout>
        <App.components.NotFound />
      </Layout.components.ErrorLayout>
    ),
    status: 404,
  };
}

export default action;
